import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['toggleInput', 'text'];
    connect() {
        this.initialize();
        this.toggleTextDisplay();
    }
    checkBoxClick = () => {
        this.toggleTextDisplay();
    };
    toggleTextDisplay() {
        const isChecked = this.toggleInputTarget.checked;
        this.textTarget.style.display = isChecked ? 'block' : 'none';
    }
}
