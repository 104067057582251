import ModalController from './abstract/ModalController';
export default class default_1 extends ModalController {
    static targets = ['text', 'storylinesContainer'];
    open = async (messageId, messageText) => {
        const url = '/desknet/storylines/' + messageId;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (response.ok) {
            const body = await response.json();
            const { storylines } = body;
            if (storylines.length == 0) {
                return;
            }
            this.textTarget.innerText = messageText;
            this.storylinesContainerTarget.innerHTML = '';
            storylines.forEach((el) => {
                const li = document.createElement('li');
                // add negativ spacing for list item
                li.classList.add('ms-n3');
                const link = document.createElement('a');
                link.innerText = el.name;
                link.href = el.link;
                li.appendChild(link);
                this.storylinesContainerTarget.appendChild(li);
            });
            this.modal.show();
        }
    };
    close = () => {
        this.modal.hide();
    };
}
