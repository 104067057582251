import AbstractController from './abstract/AbstractController';
export default class extends AbstractController {
    connect = () => {
        this.element.addEventListener('input', this.handleInput, true);
    };
    disconnect = () => {
        this.element.removeEventListener('input', this.handleInput, true);
    };
    // Main handler for contenteditable (froala) and input/textarea elements
    handleInput = (event) => {
        const target = event.target;
        if (!target)
            return;
        const isContentEditable = target.isContentEditable;
        const isInputElement = target instanceof HTMLInputElement;
        const isTextAreaElement = target instanceof HTMLTextAreaElement;
        if (isContentEditable) {
            const prevValue = target.dataset.prevValue || '';
            this.handleContentEditableInput(target, prevValue);
        }
        else if (isInputElement || isTextAreaElement) {
            const inputElement = target;
            if (isInputElement && inputElement.type !== 'text')
                return;
            const prevValue = inputElement.dataset.prevValue || '';
            this.handleInputElement(inputElement, prevValue);
        }
    };
    // Handles input for contenteditable elements (froala)
    handleContentEditableInput = (target, prevValue) => {
        const currentValue = this.getContentFromEditable(target);
        if (currentValue === null)
            return;
        const cursorPosition = this.getContentEditableCursorPosition(target);
        const inQuote = this.determineInitialQuoteState(prevValue, cursorPosition);
        this.traverseNodes(target, text => {
            return this.processTextWithQuotes(text, inQuote);
        });
        target.dataset.prevValue = this.getContentFromEditable(target) || '';
        this.setContentEditableCursor(target, cursorPosition);
    };
    // Handles input for input/textarea elements
    handleInputElement = (target, prevValue) => {
        const currentValue = target.value;
        const cursorPosition = target.selectionStart || 0;
        const inQuote = this.determineInitialQuoteState(prevValue, cursorPosition);
        const result = this.processTextWithQuotes(currentValue, inQuote);
        target.dataset.prevValue = result;
        target.value = result;
        this.setInputCursor(target, cursorPosition, currentValue.length, result.length);
    };
    // Processes text to replace quotes with opening or closing french quotes depending on current state
    processTextWithQuotes = (text, inQuote) => {
        return text
            .split('')
            .map(char => {
            if (char === '"' || char === '„' || char === '“') {
                const quote = inQuote ? '»' : '«';
                inQuote = !inQuote;
                return quote;
            }
            return char;
        })
            .join('');
    };
    // Which initial quote state at specific position is active
    determineInitialQuoteState = (prevValue, cursorPosition) => {
        return prevValue
            .slice(0, cursorPosition)
            .split('')
            .reduce((state, char) => {
            if (char === '«')
                return true;
            if (char === '»')
                return false;
            return state;
        }, false);
    };
    // Traverses all child nodes of an element and applies a processing function to text nodes
    traverseNodes = (node, processText) => {
        if (node.nodeType === Node.TEXT_NODE) {
            node.nodeValue = processText(node.nodeValue || '');
        }
        else if (node.nodeType === Node.ELEMENT_NODE) {
            node.childNodes.forEach(child => {
                this.traverseNodes(child, processText);
            });
        }
    };
    // Extracts and returns all text content from a contenteditable element
    getContentFromEditable = (target) => {
        const traverseNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.nodeValue || '';
            }
            else if (node.nodeType === Node.ELEMENT_NODE) {
                return Array.from(node.childNodes).map(traverseNodes).join('');
            }
            return '';
        };
        return traverseNodes(target);
    };
    // Retrieves the current cursor position within a froala element
    getContentEditableCursorPosition = (target) => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            return this.getNodeIndex(range.startContainer, range.startOffset, target);
        }
        return 0;
    };
    // Calculates the character index of a specific node
    getNodeIndex = (node, offset, root) => {
        let charCount = 0;
        const traverse = (currentNode) => {
            if (currentNode === node) {
                charCount += offset;
                return true;
            }
            if (currentNode.nodeType === Node.TEXT_NODE) {
                charCount += currentNode.nodeValue?.length || 0;
            }
            else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                for (const child of currentNode.childNodes) {
                    if (traverse(child))
                        return true;
                }
            }
            return false;
        };
        traverse(root);
        return charCount;
    };
    // Sets cursor position at character index within froala element
    setContentEditableCursor = (target, position) => {
        const selection = window.getSelection();
        if (selection) {
            const range = document.createRange();
            const nodeInfo = this.getNodeAtPosition(target, position);
            if (nodeInfo) {
                range.setStart(nodeInfo.node, nodeInfo.offset);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    };
    // Finds the specific node and offset at a given character position (Place cursor)
    getNodeAtPosition = (node, position) => {
        let charCount = 0;
        const traverse = (currentNode) => {
            if (currentNode.nodeType === Node.TEXT_NODE) {
                const textLength = currentNode.nodeValue?.length || 0;
                if (charCount + textLength >= position) {
                    return { node: currentNode, offset: position - charCount };
                }
                charCount += textLength;
            }
            else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                for (const child of currentNode.childNodes) {
                    const result = traverse(child);
                    if (result)
                        return result;
                }
            }
            return null;
        };
        return traverse(node);
    };
    // Sets the cursor position in a standard input or textarea element.
    setInputCursor = (target, cursorPosition, oldLength, newLength) => {
        const newCursorPosition = cursorPosition + (newLength - oldLength);
        target.setSelectionRange(newCursorPosition, newCursorPosition);
    };
}
