import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static targets = ['input'];
    connect() {
        this.setDisabled(true);
    }
    clear() {
        this.inputTargets.forEach(input => (input.value = ''));
    }
    toggle() {
        const isDisabled = this.inputTargets.every(input => input.hasAttribute('disabled'));
        this.setDisabled(!isDisabled);
    }
    setDisabled(disabled) {
        this.inputTargets.forEach(input => {
            if (disabled) {
                input.setAttribute('disabled', 'true');
            }
            else {
                input.removeAttribute('disabled');
            }
        });
    }
}
