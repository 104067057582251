import AbstractController from './abstract/AbstractController';
import { create } from '@frontify/frontify-finder';
import { Modal, Toast } from 'bootstrap';
import { checkAssetAccess } from '../helpers/frontify-helper';
import { DateTime } from 'luxon';
import { getNotificationToast } from '../helpers/notification-helper';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = [
        'imageWrapper',
        'imageRow',
        'fileNameWrapper',
        'assetWrapper',
        'assetJpg',
        'assetWebp',
        'finderButton',
        'imagesValue',
    ];
    modal;
    assetIds = [];
    lastAssetIds = [];
    connect() {
        this.assetIds = JSON.parse(this.imagesValueTarget.value || '[]');
    }
    openFinder = async () => {
        const modalElement = document.getElementById('frontifyModal');
        this.modal = Modal.getOrCreateInstance('#frontifyModal');
        this.modal.hide();
        if (this.modal === null || modalElement === null) {
            return;
        }
        const iframe = modalElement.querySelector('iframe');
        if (iframe !== null) {
            iframe.remove();
        }
        const finder = await create({
            clientId: 'client-z9mkcbf9tr6c6qff',
            domain: 'brand.sbb.ch',
            options: {
                allowMultiSelect: true,
                filters: [
                    {
                        key: 'ext',
                        values: ['mp4'],
                        inverted: true,
                    },
                ],
            },
        }).catch(() => {
            return null;
        });
        if (!finder) {
            return;
        }
        finder.onAssetsChosen(async (assets) => {
            this.assetIds = JSON.parse(this.imagesValueTarget.value || '[]');
            const path = this.element.dataset.checkAccessLink;
            if (path === '' || path === undefined) {
                return false;
            }
            if (!checkAssetAccess(assets, path)) {
                const warningToast = new Toast('#frontifyModal .toast.-warning');
                warningToast.show();
                return;
            }
            for (let i = 0; i < assets.length; i++) {
                const templateRow = document.getElementById('templateRow');
                const imageRowClone = templateRow.content.cloneNode(true);
                this.imageWrapperTarget.append(imageRowClone);
                const allImageRows = this.imageWrapperTarget.querySelectorAll('[data-frontify-finder-image-target="imageRow"]');
                this.imageRowTargets.push(allImageRows[allImageRows.length - 1]);
            }
            for (const [index, asset] of assets.entries()) {
                const rowToEdit = this.imageRowTargets[this.imageRowTargets.length - 1 - index];
                this.lastAssetIds.push(asset.id);
                const editedFileNameWrapper = rowToEdit.querySelector('[data-frontify-finder-image-target="fileNameWrapper"]');
                const editedAssetWebp = rowToEdit.querySelector('[data-frontify-finder-image-target="assetWebp"]');
                const editedAssetJpg = rowToEdit.querySelector('[data-frontify-finder-image-target="assetJpg"]');
                const noLicenseWarningNotification = getNotificationToast('header-image-no-license-warning', rowToEdit);
                const internalLicenseWarningNotification = getNotificationToast('header-image-internal-license-warning', rowToEdit);
                const expirationWarningNotification = getNotificationToast('header-image-expiration-warning', rowToEdit);
                noLicenseWarningNotification?.hide();
                internalLicenseWarningNotification?.hide();
                expirationWarningNotification?.hide();
                if (!asset || !asset.previewUrl || !editedFileNameWrapper || !editedAssetWebp || !editedAssetJpg) {
                    return;
                }
                this.assetIds.push(asset.id);
                editedFileNameWrapper.innerText = asset.filename;
                const radioButtonWrapper = rowToEdit.querySelector('[name="production[selectedImagePlaceholder]"]');
                if (radioButtonWrapper) {
                    radioButtonWrapper.value = asset.id;
                }
                const currentRow = editedAssetWebp.closest('[data-frontify-finder-image-target="assetWrapper"]');
                if (!currentRow) {
                    return;
                }
                currentRow.dataset.id = asset.id;
                const previewURL = new URL(asset.previewUrl);
                previewURL.searchParams.append('width', '400');
                previewURL.searchParams.append('format', 'webp');
                editedAssetWebp.srcset = previewURL.toString();
                const previewURLFallback = new URL(asset.previewUrl);
                previewURLFallback.searchParams.append('width', '400');
                previewURLFallback.searchParams.append('format', 'jpg');
                editedAssetJpg.src = previewURLFallback.toString();
                rowToEdit.querySelector('[data-header-image-licences]').innerHTML =
                    //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                    asset.licenses.length > 0
                        ? //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                            asset.licenses?.map(l => l.title).join(', ')
                        : await translate('news_article.label_no_licenses', 'workflow');
                rowToEdit.querySelector('[data-header-image-expires-on]').innerHTML = asset.expiresAt
                    ? DateTime.fromISO(asset.expiresAt).toFormat('dd.LL.yyyy')
                    : await translate('image.upload_asset_modal.label_no_expiration_date', 'workflow');
                //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                if (asset.licenses.length < 1 || asset.licenses.find(l => l.title === 'Keine Lizenz')) {
                    noLicenseWarningNotification?.show();
                }
                //@ts-ignore (FrontifyAsset.licenses is incorrectly typed as object rather than array)
                if (asset.licenses.length > 0 && asset.licenses.find(l => l.title === 'Intern')) {
                    internalLicenseWarningNotification?.show();
                }
                if (asset.expiresAt && DateTime.fromISO(asset.expiresAt).diffNow().valueOf() < 0) {
                    expirationWarningNotification?.show();
                }
            }
            if (this.imagesValueTarget === null) {
                return;
            }
            this.imagesValueTarget.value = JSON.stringify(this.assetIds);
            this.imageWrapperTarget.classList.remove('d-none');
            this.modal?.hide();
        });
        finder.onCancel(() => {
            this.modal?.hide();
        });
        const finderWrapper = document.querySelector('.frontify-finder-wrapper');
        if (finderWrapper === null) {
            return;
        }
        finder.mount(finderWrapper);
        this.modal.show();
    };
    removeImage = (event) => {
        const imageRow = event.target.closest('.image-row');
        if (imageRow === null) {
            return;
        }
        const assetWrapper = imageRow.querySelector('[data-id]');
        const headerImageSelector = imageRow.querySelector('[data-selected-image-placeholer]');
        const selectedImageValueField = document.querySelector('[name="production[selectedImage]"]');
        imageRow.remove();
        if (!assetWrapper ||
            assetWrapper.dataset.id === undefined ||
            !headerImageSelector ||
            !selectedImageValueField) {
            return;
        }
        const indexToRemove = this.assetIds.indexOf(assetWrapper.dataset.id);
        this.assetIds.splice(indexToRemove, 1);
        this.imagesValueTarget.value = JSON.stringify(this.assetIds);
        if (headerImageSelector.checked) {
            selectedImageValueField.value = '';
        }
    };
    selectImage = (event) => {
        const selectedImageValueField = document.querySelector('[name="production[selectedImage]"]');
        if (!selectedImageValueField) {
            return;
        }
        selectedImageValueField.value = event.target.value;
    };
    handleNewsArticleSelection = (event) => {
        const radios = document.querySelectorAll('[data-selected-image-placeholer]');
        Array.from(radios).map(radio => {
            radio.closest('[data-button]')?.classList.toggle('d-none', event.detail.value == '');
        });
    };
}
