import { submitFormAsRequest } from '../helpers/form-helper';
import AbstractController from './abstract/AbstractController';
import * as Sentry from '@sentry/browser';
export default class default_1 extends AbstractController {
    static targets = ['commentArea', 'button', 'commentList'];
    static outlets = ['counted-field', 'sidebar', 'delete-modal'];
    connect = () => {
        this.initcommentArea();
        this.formElement = document.querySelector('[name="comment"]');
    };
    initcommentArea = () => {
        this.commentAreaTarget.addEventListener('input', this.updateCommentButtons);
        const hiddenTextArea = document.getElementById('workflow_state_change_comment');
        const prefillValue = hiddenTextArea ? hiddenTextArea.value : null;
        if (!prefillValue) {
            return;
        }
        const mentionElement = document.querySelector('[data-change-workflow-step-modal-target="commentArea"]');
        mentionElement.innerText = prefillValue;
    };
    updateCommentButtons = () => {
        for (const button of this.buttonTargets) {
            button.disabled = this.commentAreaTarget.innerText.trim() === '';
        }
    };
    clearCommentArea = () => {
        this.countedFieldOutlet.clear();
    };
    clickSubmitComment = async () => {
        if (this.formElement === null) {
            return;
        }
        this.submitComment(this.formElement, this.commentAreaTarget);
    };
    submitComment = async (formElement, commentArea) => {
        const commentFormField = formElement.querySelector('[data-control="comment-form-field"]');
        if (commentFormField === null) {
            return false;
        }
        commentFormField.value = commentArea.innerHTML;
        const response = await submitFormAsRequest(formElement);
        if (response.status !== 200) {
            if (Sentry.isInitialized()) {
                Sentry.captureMessage('Could not submit form request');
            }
            return false;
        }
        // When KOM-11548 occurs, the response is HTML and can't be parsed
        let jsonResponse = undefined;
        try {
            jsonResponse = await response.json();
        }
        catch (e) {
            if (Sentry.isInitialized()) {
                Sentry.captureException(e);
            }
        }
        if (!jsonResponse) {
            return false;
        }
        const { comment, current_user_is_observing } = jsonResponse;
        if (current_user_is_observing) {
            document.getElementById('flexSwitchCheckDefault').checked = true;
        }
        this.countedFieldOutlet.clear();
        this.appendCommentToList(comment);
        return true;
    };
    appendCommentToList = async (comment) => {
        this.clearCommentArea();
        this.commentListTarget.innerHTML += comment;
        this.updateCommentButtons();
    };
    clickDeleteComment = async (e) => {
        const button = e.currentTarget;
        const { path, token } = button.dataset;
        if (!(path && token)) {
            return;
        }
        const url = path;
        const formData = new FormData();
        formData.append('_token', token);
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            return;
        }
        const jsonResponse = await response.json();
        this.removeCommentFromList(jsonResponse.id);
    };
    removeCommentFromList = (id) => {
        const commentElement = this.commentListTarget.querySelector(`[data-comment-id='${id}']`);
        commentElement?.remove();
    };
}
