import AbstractController from './abstract/AbstractController';
import { Toast } from 'bootstrap';
export default class default_1 extends AbstractController {
    static targets = ['template'];
    toasts = new Map();
    new_and_show = (id, text) => {
        if (!this.toasts.has(id)) {
            this.new_toast(id, text);
        }
        this.show(id);
    };
    show = (id) => {
        const toast = this.toasts.get(id);
        if (!toast) {
            return;
        }
        toast.show();
    };
    hide = (id) => {
        const toast = this.toasts.get(id);
        if (!toast) {
            return;
        }
        toast.hide();
    };
    new_toast = (id, text) => {
        const new_toast = this.clone_and_get(id, text);
        if (!new_toast) {
            // eslint-disable-next-line no-console
            console.error('Could not create new toast');
            return;
        }
        const toast = new Toast(new_toast);
        this.toasts.set(id, toast);
    };
    clone_and_get = (id, text) => {
        const clone = this.templateTarget.content.cloneNode(true);
        this.element.appendChild(clone);
        const element = this.element.lastElementChild;
        element.id = id;
        element.setAttribute('data-notification', id);
        const content = this.element.querySelector('[data-toast-textcontent]');
        if (content) {
            content.textContent = text;
        }
        return element;
    };
}
