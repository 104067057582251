import AbstractController from './abstract/AbstractController';
import translate from '../helpers/translation-helper';
import { hideError, showError } from '../helpers/form-helper';
export default class default_1 extends AbstractController {
    static targets = [
        'component',
        'list',
        'name',
        'nameInput',
        'url',
        'urlInput',
        'editButton',
        'saveButton',
        'cancelButton',
        'deleteButton',
        'deletePopoverButton',
        'addButton',
        'splitter',
        'template',
    ];
    connect = () => {
        this.checkForNoComments();
    };
    checkForNoComments = () => {
        if (this.componentTargets.length == 0) {
            this.splitterTarget.classList.add('d-none');
        }
    };
    addComponent = async () => {
        if (this.componentTargets.length == 0) {
            this.splitterTarget.classList.remove('d-none');
        }
        const clonedComponent = this.templateTarget.content.firstElementChild?.cloneNode(true);
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        const index = this.componentTargets.indexOf(clonedElement);
        //display correct fields
        this.displayEditState(index);
        //clean data
        this.nameInputTargets[index].value = '';
        this.urlInputTargets[index].value = '';
        this.nameTargets[index].innerText = '';
        this.urlTargets[index].innerText = '';
        this.urlTargets[index].href = '';
        this.updateComponentIndex(clonedElement);
    };
    delete = async (event) => {
        const index = this.deleteButtonTargets.indexOf(event.currentTarget);
        this.componentTargets[index].remove();
        this.checkForNoComments();
    };
    edit = (event) => {
        const index = this.editButtonTargets.indexOf(event.currentTarget);
        this.nameInputTargets[index].value = this.nameTargets[index].innerText;
        this.urlInputTargets[index].value = this.urlTargets[index].innerText;
        this.displayEditState(index);
    };
    save = async (event) => {
        const index = this.saveButtonTargets.indexOf(event.currentTarget);
        hideError(this.nameInputTargets[index]);
        hideError(this.urlInputTargets[index]);
        let hasErrors = false;
        if (this.urlInputTargets[index].value.length == 0) {
            hasErrors = true;
            showError(this.urlInputTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        if (this.nameInputTargets[index].value.length == 0) {
            hasErrors = true;
            showError(this.nameInputTargets[index], await translate('workflow.validation.required', 'admin'));
        }
        if (!this.isValidUrl(this.urlInputTargets[index].value)) {
            hasErrors = true;
            showError(this.urlInputTargets[index], await translate('workflow.validation.url_not_valid', 'admin'));
        }
        if (hasErrors) {
            return;
        }
        this.displaySavedState(index);
        this.nameTargets[index].innerText = this.nameInputTargets[index].value;
        this.urlTargets[index].innerText = this.urlInputTargets[index].value;
        this.urlTargets[index].href = this.urlInputTargets[index].value;
    };
    cancel = (event) => {
        const index = this.cancelButtonTargets.indexOf(event.currentTarget);
        //check if createdBy is already set, this means this comment has existed before
        if (this.nameTargets[index].innerText == '' && this.urlTargets[index].innerText == '') {
            //delete componenent
            this.componentTargets[index].remove();
            this.checkForNoComments();
            return;
        }
        this.saveButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.remove('d-none');
        this.deletePopoverButtonTargets[index].classList.remove('d-none');
        this.nameInputTargets[index].classList.add('d-none');
        this.urlInputTargets[index].classList.add('d-none');
        this.nameTargets[index].classList.remove('d-none');
        this.urlTargets[index].classList.remove('d-none');
        this.nameInputTargets[index].value = this.nameTargets[index].innerText;
        this.urlInputTargets[index].value = this.urlTargets[index].innerText;
    };
    updateComponentIndex = (component) => {
        const index = this.componentTargets.length - 1;
        if (index < 0) {
            return;
        }
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/\d+/, index.toString()));
            }
        }
    };
    displayEditState = (index) => {
        this.saveButtonTargets[index].classList.remove('d-none');
        this.cancelButtonTargets[index].classList.remove('d-none');
        this.editButtonTargets[index].classList.add('d-none');
        this.deletePopoverButtonTargets[index].classList.add('d-none');
        this.nameTargets[index].classList.add('d-none');
        this.urlTargets[index].classList.add('d-none');
        this.nameInputTargets[index].classList.remove('d-none');
        this.urlInputTargets[index].classList.remove('d-none');
    };
    displaySavedState = (index) => {
        this.saveButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.remove('d-none');
        this.deletePopoverButtonTargets[index].classList.remove('d-none');
        this.nameInputTargets[index].classList.add('d-none');
        this.urlInputTargets[index].classList.add('d-none');
        this.nameTargets[index].classList.remove('d-none');
        this.urlTargets[index].classList.remove('d-none');
    };
    isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        }
        catch (e) {
            return false;
        }
    };
}
