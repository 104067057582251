import AbstractController from './abstract/AbstractController';
import tippy from 'tippy.js';
import translate from '../helpers/translation-helper';
export default class default_1 extends AbstractController {
    static targets = ['openPopoverButton', 'realButton'];
    localTippy = null;
    connect = async () => {
        const sureLabel = await translate('label.sure', 'base');
        const deleteLabel = await translate('label.delete', 'base');
        const cancelLabel = await translate('label.cancel', 'base');
        this.localTippy = tippy(this.openPopoverButtonTarget, {
            theme: 'sbb',
            content: () => {
                const workflowElementExists = Boolean(this.element.closest('.workflow-element'));
                return ('<div class="d-flex flex-column">' +
                    '<p class="m-0">' +
                    sureLabel +
                    '</p>' +
                    '<div class="d-flex">' +
                    (workflowElementExists
                        ? '<button data-action="click->delete-popover#deleteWorkflowElement" ' +
                            'class="sbb-button m-0 mb-1 mt-1 primary medium delete-button" type="button">' +
                            deleteLabel +
                            '</button>'
                        : '<button ' +
                            this.element.dataset.deleteAction +
                            ' class="sbb-button m-0 mb-1 mt-1 primary medium" type="button">' +
                            deleteLabel +
                            '</button>') +
                    '<button type="button" data-action="click->delete-popover#clickCancelButton" class="sbb-button secondary mb-1 mt-1 enabled-click medium">' +
                    cancelLabel +
                    '</button>' +
                    '</div>' +
                    '</div>');
            },
            allowHTML: true,
            trigger: 'click',
            interactive: true,
        });
        this.openPopoverButtonTarget.disabled = false;
    };
    clickRealButton = () => {
        this.realButtonTarget.click();
    };
    clickCancelButton = () => {
        if (this.localTippy) {
            this.localTippy.hide();
        }
    };
    deleteWorkflowElement = () => {
        const workflowEelement = this.element.closest('.workflow-element');
        if (workflowEelement) {
            workflowEelement.remove();
        }
        this.localTippy?.hide();
    };
}
