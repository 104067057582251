import AbstractController from '../abstract/AbstractController';
import { DateTime } from 'luxon';
import { initTooltips } from '../../helpers/tooltip-helper';
import _ from 'lodash';
import TomSelect from "tom-select";
export default class default_1 extends AbstractController {
    static targets = ['textFilter', 'statusFilter', 'dateFilter', 'table'];
    static values = {
        limit: Number,
        offset: Number,
        url: String,
        stati: Array
    };
    connect() {
        initTooltips(this.element);
        this.initFilters();
    }
    fetchStati = async () => {
        const response = await fetch('/workflow-stati', {
            method: 'GET'
        });
        this.statiValue = await response.json();
    };
    fetchFilteredGhosts = async () => {
        if (!(this.from?.isValid && this.to?.isValid)) {
            return;
        }
        const fetchButton = document.getElementById('fetch-filtered-ghosts-button');
        fetchButton.disabled = true;
        const response = await fetch('/workflow-ghosts', {
            method: 'POST',
            body: JSON.stringify({
                date_from: this.from,
                date_to: this.to,
                status: this.status,
                text: this.text?.trim().toLowerCase()
            })
        });
        const res = await response.text();
        const dataContainer = document.getElementById('workflow-overview-data-container');
        dataContainer.innerHTML = res;
        fetchButton.disabled = false;
    };
    initFilters = async () => {
        this.text = '';
        this.textFilterTarget.addEventListener('input', e => {
            this.text = e.target.value;
        });
        this.dateFilterTarget.addEventListener('input', () => {
            this.from = DateTime.fromJSDate(this.dateFilterTarget._flatpickr.selectedDates[0]);
            this.to = DateTime.fromJSDate(this.dateFilterTarget._flatpickr.selectedDates[1]);
        });
        this.textFilterTarget.addEventListener('keypress', e => {
            if (e.key === 'Enter') {
                this.fetchFilteredGhosts();
            }
        });
        this.status = [];
        document.addEventListener('DOMContentLoaded', async () => {
            this.dateFilterTarget.dispatchEvent(new Event('input'));
            const statusFilter = new TomSelect(this.statusFilterTarget, { plugins: ['sbb-multiselect'] });
            statusFilter.wrapper.onclick = () => {
                statusFilter.control.click();
            };
            await this.fetchStati();
            statusFilter.addOptions(this.statiValue);
            statusFilter.on('change', (value) => {
                this.status = value;
            });
            await this.fetchFilteredGhosts();
        });
    };
    updateFilterOptions = () => {
        this.updateTomSelectOptions(this.statusFilterTarget, 'data-topic-status');
    };
    updateTomSelectOptions = (input, selector, groupSelector) => {
        const elements = Array.from(this.tableTarget.querySelectorAll(`[${selector}]`));
        const options = _.uniq(elements.map(item => {
            return {
                group: groupSelector ? item.getAttribute(groupSelector) : null,
                value: item.getAttribute(selector),
            };
        }));
        if (groupSelector) {
            input.tomselect?.clearOptionGroups();
            const groups = _.uniq(options.map(o => o.group));
            for (const group of groups) {
                input.tomselect?.addOptionGroup(group, {
                    label: group,
                });
            }
        }
        const tomOptions = [];
        for (const option of options) {
            tomOptions.push({
                text: option.value,
                value: `${option.group}: ${option.value}`,
                optgroup: option.group,
            });
            input.tomselect?.clearOptions();
            input.tomselect?.addOptions(tomOptions);
        }
    };
    resetFilters = () => {
        this.text = '';
        this.textFilterTarget.value = '';
        this.status = [];
        this.statusFilterTarget.tomselect?.clear();
        const dateNow = new Date();
        const dateFuture = new Date();
        dateFuture.setDate(new Date().getDate() + 14);
        this.from = DateTime.fromJSDate(dateNow);
        this.to = DateTime.fromJSDate(dateFuture);
        this.dateFilterTarget._flatpickr.setDate([Date.now(), Date.now() + 1209600000]);
        this.fetchFilteredGhosts();
    };
}
