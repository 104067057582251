import AbstractController from './abstract/AbstractController';
export default class default_1 extends AbstractController {
    static outlets = ['connected-storylines-modal'];
    static targets = [
        'list',
        'component',
        'input',
        'deleteButton',
        'editButton',
        'saveButton',
        'cancelButton',
        'content',
        'addButton',
        'inputWrapper',
        'infoButton',
    ];
    connect = () => {
        this.template = this.componentTargets[0].cloneNode(true);
    };
    add = () => {
        const clonedComponent = this.template.cloneNode(true);
        const clonedElement = this.listTarget.appendChild(clonedComponent);
        const index = this.componentTargets.indexOf(clonedElement);
        this.inputTargets[index].value = '';
        this.contentTargets[index].innerText = '';
        this.inputWrapperTargets[index].classList.remove('d-none');
        this.contentTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.add('d-none');
        this.deleteButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.remove('d-none');
        this.saveButtonTargets[index].classList.remove('d-none');
        this.componentTargets[index].dataset.messageId = '0';
        this.updateComponentIndex(clonedElement);
    };
    save = async (event) => {
        const url = this.element.dataset.savePath;
        const index = this.saveButtonTargets.indexOf(event.currentTarget);
        const content = this.inputTargets[index].value;
        if (!url) {
            return null;
        }
        const formData = new FormData();
        formData.append('content', content);
        formData.append('messageId', this.componentTargets[index].dataset.messageId ?? '0');
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        if (response.ok) {
            const body = await response.json();
            const { id } = body;
            this.inputWrapperTargets[index].classList.add('d-none');
            this.contentTargets[index].classList.remove('d-none');
            this.contentTargets[index].innerText = content;
            this.componentTargets[index].dataset.messageId = id;
            this.componentTargets[index].dataset.deletePath = this.componentTargets[index].dataset.deletePath?.replace(/\/desknet\/\d+/, `/desknet/${id}`);
            this.saveButtonTargets[index].classList.add('d-none');
            this.cancelButtonTargets[index].classList.add('d-none');
            this.editButtonTargets[index].classList.remove('d-none');
            this.deleteButtonTargets[index].classList.remove('d-none');
            this.inputWrapperTargets[index].classList.add('d-none');
            this.contentTargets[index].classList.remove('d-none');
        }
    };
    edit = (event) => {
        const index = this.editButtonTargets.indexOf(event.currentTarget);
        this.saveButtonTargets[index].classList.remove('d-none');
        this.cancelButtonTargets[index].classList.remove('d-none');
        this.editButtonTargets[index].classList.add('d-none');
        this.deleteButtonTargets[index].classList.add('d-none');
        this.inputWrapperTargets[index].classList.remove('d-none');
        this.contentTargets[index].classList.add('d-none');
    };
    showChildStorylines = (event) => {
        const index = this.infoButtonTargets.indexOf(event.currentTarget);
        const messageId = this.componentTargets[index].dataset.messageId ?? '0';
        this.connectedStorylinesModalOutlet.open(messageId, this.contentTargets[index].innerText);
    };
    cancel = (event) => {
        const index = this.cancelButtonTargets.indexOf(event.currentTarget);
        this.saveButtonTargets[index].classList.add('d-none');
        this.cancelButtonTargets[index].classList.add('d-none');
        this.editButtonTargets[index].classList.remove('d-none');
        this.deleteButtonTargets[index].classList.remove('d-none');
        this.inputWrapperTargets[index].classList.add('d-none');
        this.contentTargets[index].classList.remove('d-none');
        this.inputTargets[index].value = this.contentTargets[index].innerText;
    };
    delete = async (event) => {
        const index = this.deleteButtonTargets.indexOf(event.currentTarget);
        const url = this.componentTargets[index].dataset.deletePath;
        if (!url) {
            return null;
        }
        const formData = new FormData();
        formData.append('messageId', this.componentTargets[index].dataset.messageId ?? '0');
        const response = await fetch(url, {
            method: 'DELETE',
            body: formData,
        });
        if (response.ok) {
            this.componentTargets[index].remove();
        }
    };
    updateComponentIndex = (component) => {
        const index = this.componentTargets.length - 1;
        if (index < 0) {
            return;
        }
        const attributesToUpdate = [
            'id',
            'name',
            'aria-controls',
            'for',
            'aria-labelledby',
            'data-bs-target',
            'data-model',
        ];
        for (const attribute of attributesToUpdate) {
            const elements = component.querySelectorAll(`[${attribute}]`);
            for (const element of elements) {
                element.setAttribute(attribute, element.getAttribute(attribute).replace(/\d+/, index.toString()));
            }
        }
    };
}
