export const submitFormAsRequest = async (formElement) => {
    const url = formElement.action;
    const formData = new FormData(formElement);
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    });
    return response;
};
export const hideError = (inputElement) => {
    const formField = inputElement.closest('.sbb-form-field');
    const errorWrapper = formField?.querySelector('.error-wrapper');
    if (!errorWrapper) {
        return;
    }
    errorWrapper.innerHTML = '';
    formField?.classList.remove('-has-error');
};
export const showError = (inputElement, errorMessage) => {
    const formField = inputElement.closest('.sbb-form-field');
    const errorWrapper = formField?.querySelector('.error-wrapper');
    if (!errorWrapper) {
        return;
    }
    errorWrapper.innerHTML = errorMessage;
    formField?.classList.add('-has-error');
};
export const hideErrors = (formElement) => {
    const formFields = formElement.querySelectorAll('.sbb-form-field');
    for (const formField of formFields) {
        formField.classList.remove('-has-error');
        const errorWrapper = formField.querySelector('.error-wrapper');
        if (!errorWrapper) {
            return;
        }
        errorWrapper.innerHTML = '';
    }
};
