import AbstractController from './abstract/AbstractController';
import { getNotificationToast } from '../helpers/notification-helper';
export default class default_1 extends AbstractController {
    static targets = ['copyButton', 'textContent', 'hashtagContent'];
    copySuccessNotification;
    connect() {
        this.copySuccessNotification = getNotificationToast('copy-success-text');
    }
    copy = (event) => {
        const button = event.currentTarget;
        const type = button.getAttribute('data-type');
        const language = button.getAttribute('data-language');
        const index = button.getAttribute('data-index');
        let copiedText = '';
        if (type === 'text') {
            if (language && index) {
                const target = this.textContentTargets.find(target => target.dataset.language === language && target.dataset.index === index);
                copiedText = target ? target.innerText.trim() : '';
            }
        }
        else if (type === 'hashtag') {
            if (language) {
                this.hashtagContentTargets.forEach(target => {
                    if (target.dataset.language === language) {
                        copiedText += target.innerText.trim() + ' ';
                    }
                });
            }
        }
        if (copiedText) {
            navigator.clipboard.writeText(copiedText);
            this.showNotification();
        }
    };
    showNotification() {
        if (this.copySuccessNotification) {
            this.copySuccessNotification.hide();
            setTimeout(() => {
                this.copySuccessNotification?.show();
                setTimeout(() => {
                    this.copySuccessNotification?.hide();
                }, 5000);
            }, 0);
        }
    }
}
